import React, { CSSProperties } from 'react'
import classNames from 'classnames'
import { mergeQueryParameters } from '@/utils/UriBuilder'

interface EmbeddedVideoProps {
  className?: string
  title?: string
  url: string
  iframeStyle?: CSSProperties
}

export const EmbeddedVideo: React.FC<EmbeddedVideoProps> = ({ className, title, url, iframeStyle }) => {
  return (
    <span className={classNames('block aspect-w-16 aspect-h-9 bg-black rounded-2xl', className)}>
      <iframe
        width="100%"
        height="100%"
        src={mergeQueryParameters(url, { rel: '0' })}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={iframeStyle}
      />
    </span>
  )
}
