import { gql as contentfulGQL } from '@apollo/client'

export const getQuizQuery = contentfulGQL/* GraphQL */ `
  query getQuiz($id: String!, $preview: Boolean) {
    quiz(id: $id, preview: $preview) {
      title
      banner
      questionsCollection {
        items {
          answersCollection {
            items {
              results
              sys {
                id
              }
              text
            }
            total
          }
          sys {
            id
          }
          image
          name
          text
        }
        total
      }
      resultsCollection {
        items {
          image
          name
          sys {
            id
          }
          text
        }
        total
      }
      sys {
        id
      }
    }
  }
`
