// components/QuizRenderer.js
import { useQuery } from '@apollo/client'
import { getContentfulClient } from '@/services/ApolloClient'
import { useLocale } from '@/utils/LocaleUtil'
import { QuizDisplay } from './QuizDisplay'
import { getQuizQuery } from './QuizQueries'

interface QuizRendererProps {
  id: string
  preview?: boolean
}

export const QuizRenderer: React.FC<QuizRendererProps> = ({ id, preview = false }) => {
  const { locale } = useLocale()
  const client = getContentfulClient({ locale, preview })
  const { data, loading } = useQuery(getQuizQuery, { client, variables: { id, preview } })

  return !loading && data?.quiz ? <QuizDisplay {...data?.quiz} /> : <div>Loading...</div>
}

export default QuizRenderer
