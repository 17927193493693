// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import kebabCase from 'lodash/kebabCase'
import { ParagraphMD, HeadingXL, HeadingLG, HeadingMD, HeadingSM, HeadingXS } from '@/atoms/Text'
import { MerchScrollerRenderer } from '@/organisms/HorizontalShopifyMerchScroller/MerchScrollerRenderer'
import { QuizRenderer } from '@/organisms/Quiz'
import { ReactFCC } from '@/types/react'
import { useHyperlink } from './useHyperlink'

const Text: ReactFCC = ({ children }) => (
  <ParagraphMD className="pb-4" color="inherit" weight="medium">
    {children}
  </ParagraphMD>
)
const Heading1: ReactFCC = ({ node, children }) => (
  <HeadingXL className="pb-8 pt-4" color="inherit" id={kebabCase(node.content[0].value)} weight="bold">
    {children}
  </HeadingXL>
)
const Heading2: ReactFCC = ({ node, children }) => (
  <HeadingLG color="inherit" className="pb-8 pt-4" id={kebabCase(node.content[0].value)} weight="bold">
    {children}
  </HeadingLG>
)
const Heading3: ReactFCC = ({ node, children }) => (
  <HeadingMD color="inherit" className="pb-8 pt-4" id={kebabCase(node.content[0].value)} weight="bold">
    {children}
  </HeadingMD>
)
const Heading4: ReactFCC = ({ node, children }) => {
  return (
    <HeadingSM color="inherit" className="pb-8 pt-4" id={kebabCase(node.content[0].value)} weight="bold">
      {children}
    </HeadingSM>
  )
}
const Heading5: ReactFCC = ({ node, children }) => (
  <HeadingXS color="inherit" className="pb-8 pt-4" id={kebabCase(node.content[0].value)} weight="bold">
    {children}
  </HeadingXS>
)
const Heading6: ReactFCC = ({ node, children }) => (
  <HeadingXS className="mb-4" color="inherit" id={kebabCase(node.content[0].value)} weight="semibold">
    {children}
  </HeadingXS>
)

const List: ReactFCC = ({ children }) => <ul className="list-disc pl-8">{children}</ul>
const OrderedList: ReactFCC = ({ children }) => <ol className="list-decimal pl-8">{children}</ol>
const ListItem: ReactFCC = ({ children }) => <li>{children}</li>

const BlockQuote: ReactFCC = ({ children }) => (
  <div className="my-4 ml-6 border-l-4 border-l-gray-400 pl-2 pt-4">{children}</div>
)

const Divide: ReactFCC = () => <hr className="my-4 pb-4 text-gray-300" />

const TableHeader: ReactFFC = ({ children }) => <th className="border bg-gray-200 px-4 pt-4">{children}</th>
const TableCell: ReactFFC = ({ children }) => <td className="border px-4 pt-4">{children}</td>

function renderText(text: string) {
  // converts \n characters to <br /> elements
  return text.split('\n').reduce((children, textSegment, index) => {
    return [...children, index > 0 && <br key={index} />, textSegment]
  }, [])
}

export const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <Text>{children}</Text>,
    [BLOCKS.HEADING_1]: (node, children) => <Heading1 node={node}>{children}</Heading1>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading2 node={node}>{children}</Heading2>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading3 node={node}>{children}</Heading3>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading4 node={node}>{children}</Heading4>,
    [BLOCKS.HEADING_5]: (node, children) => <Heading5 node={node}>{children}</Heading5>,
    [BLOCKS.HEADING_6]: (node, children) => <Heading6 node={node}>{children}</Heading6>,
    [BLOCKS.UL_LIST]: (_, children) => <List>{children}</List>,
    [BLOCKS.OL_LIST]: (_, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.LIST_ITEM]: (_, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.QUOTE]: (_, children) => <BlockQuote>{children}</BlockQuote>,
    [BLOCKS.HR]: () => <Divide />,
    [BLOCKS.TABLE_HEADER_CELL]: (_, children) => <TableHeader>{children}</TableHeader>,
    [BLOCKS.TABLE_CELL]: (_, children) => <TableCell>{children}</TableCell>,
    [INLINES.HYPERLINK]: useHyperlink,
  },
  renderText: (text: string) => {
    // converts \n characters to <br /> elements
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  },
}

export function renderOptions({ links, preview = false }) {
  const entryBlockMap = new Map()

  for (const entry of links.entries.block) {
    entryBlockMap.set(entry.sys.id, entry)
  }

  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entry = entryBlockMap.get(node.data.target.sys.id)

        if (entry.__typename === 'Quiz') {
          return <QuizRenderer id={entry.sys.id} preview={preview} />
        } else if (entry.__typename === 'ShopifyStore') {
          return <MerchScrollerRenderer id={entry.sys.id} preview={preview} />
        } else {
          return <div id={`entry-${entry.sys.id}`} />
        }
      },
      ...options.renderNode,
    },
    renderText,
  }
}
