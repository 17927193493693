import { useQuery } from '@apollo/client'
import { gql as contentfulGQL } from '@apollo/client'
import { getContentfulClient } from '@/services/ApolloClient'
import { ReactFCC } from '@/types/react'
import { useLocale } from '@/utils/LocaleUtil'
import { HorizontalShopifyMerchScroller } from './HorizontalShopifyMerchScroller'

const getShopifyStoreQuery = contentfulGQL/* GraphQL */ `
  query getShopifyStore($id: String!, $preview: Boolean) {
    shopifyStore(id: $id, preview: $preview) {
      accessToken
      handle
      storeUrl
      storeHref
    }
  }
`

interface MerchScrollerRendererProps {
  id: string
  preview?: boolean
}

export const MerchScrollerRenderer: ReactFCC<MerchScrollerRendererProps> = ({ id, preview = false }) => {
  const { locale } = useLocale()
  const client = getContentfulClient({ locale, preview })
  const { data, loading } = useQuery(getShopifyStoreQuery, { client, variables: { id, preview } })

  return !loading && data ? (
    <HorizontalShopifyMerchScroller
      accessToken={data?.shopifyStore?.accessToken as string}
      analytics={{
        context: 'rich-text',
        program: data?.shopifyStore?.handle as string,
      }}
      handle={data?.shopifyStore?.handle as string}
      storeUrl={data?.shopifyStore?.storeUrl as string}
      storeHref={data?.shopifyStore?.storeHref as string}
      textColor="inherit"
    />
  ) : null
}
