import { ReactNode } from 'react'
import { ParagraphLG } from '@/atoms/Typography'

export const renderer = {
  paragraph(children: ReactNode) {
    return (
      // @ts-expect-error Property `elementId` does not exist
      <ParagraphLG className="mb-2" color="current" key={this.elementId}>
        {children}
      </ParagraphLG>
    )
  },
  link(href: string, text: ReactNode) {
    return (
      // @ts-expect-error Property `elementId` does not exist
      <a className="cursor-pointer text-oxide underline" href={href} key={this.elementId}>
        {text}
      </a>
    )
  },
}
