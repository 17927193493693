import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { ParagraphMD } from '@/atoms/Text'
import { QuizAnswer, QuizQuestion } from '@/types/codegen-contentful'
import { buildCloudinaryImageUrlFromContentfulObject } from '@/utils/Cloudinary'
import { QuizAnswerDisplay } from './QuizAnswerDisplay'

export const QuizQuestionDisplay: React.FC<QuizQuestion> = ({ answersCollection, image, name, sys, text }) => {
  return (
    <div className="py-2">
      {(image?.length || 0) > 0 && (
        <div className="relative my-4 h-60 w-full md:h-80 lg:h-[420px]">
          <Image
            alt={image.alt}
            layout="fill"
            loading="lazy"
            objectFit="cover"
            src={buildCloudinaryImageUrlFromContentfulObject(image?.[0], 'c_fill,w_672')}
          />
        </div>
      )}
      <ParagraphMD>{text}</ParagraphMD>
      {answersCollection?.items.map((answer) => (
        <QuizAnswerDisplay
          id={sys.id as string}
          key={answer?.sys.id}
          name={name as string}
          {...(answer as QuizAnswer)}
        />
      ))}
    </div>
  )
}
