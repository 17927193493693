// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Case, Default, Switch } from 'react-if'
import { ExternalLink } from '@/atoms/ExternalLink'
import { InternalLink } from '@/atoms/InternalLink'
import { paths } from '@/constants/paths'
import { ManageCookieConsentLink } from '@/molecules/CookieManagement'
import { EmbeddedVideo } from '@/molecules/EmbeddedVideo'
import { AngelFundingLink } from '@/molecules/InvestLink'
import { useSafeTrack } from '@/utils/analytics'
import { EmbeddedImage } from './EmbeddedImage'

export function useHyperlink(node, children) {
  const uri = node.data.uri
  const title = node.content[0].value
  const angelHref = uri.startsWith(paths.base)
  const youtubeHref = uri.startsWith('https://www.youtube.com/embed')
  const loomHref = uri.startsWith('https://www.loom.com/embed')
  const vimeoHref = uri.startsWith('https://player.vimeo.com/video')
  const embeddedVideoHref = youtubeHref || loomHref || vimeoHref
  const cloudinaryHref = uri.startsWith('https://images.angelstudios.com')
  const investHref = uri.startsWith(paths.external.invest.index)
  const hashHref = uri.startsWith('#')
  const externalHref =
    !angelHref &&
    !embeddedVideoHref &&
    !cloudinaryHref &&
    !investHref &&
    !hashHref &&
    (uri.startsWith('https://') || uri.startsWith('http://'))
  // TODO where is this getting set at?
  const manageCookiesHref = uri === '#osano' || uri === '#cookies'

  const track = useSafeTrack()

  const handleClick = () => {
    track('On Tap', { element_name: 'rich_text_link', location: uri, value: title })
  }

  return (
    <Switch>
      <Case condition={embeddedVideoHref}>
        <EmbeddedVideo title={title} url={uri} />
      </Case>
      <Case condition={cloudinaryHref}>
        <EmbeddedImage title={title} uri={uri} />
      </Case>
      <Case condition={investHref}>
        <AngelFundingLink className="inline text-oxide" href={uri} linkContext="blog" onClick={handleClick}>
          {children}
        </AngelFundingLink>
      </Case>
      <Case condition={manageCookiesHref}>
        <ManageCookieConsentLink>
          <span className="cursor-pointer text-oxide underline">{children}</span>
        </ManageCookieConsentLink>
      </Case>
      <Case condition={!externalHref}>
        <InternalLink href={uri} onClick={handleClick}>
          <span className="cursor-pointer text-oxide underline">{children}</span>
        </InternalLink>
      </Case>
      <Default>
        <ExternalLink href={uri} onClick={handleClick}>
          <span className="cursor-pointer text-oxide underline">{children}</span>
        </ExternalLink>
      </Default>
    </Switch>
  )
}
