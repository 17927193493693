import { FormEvent, useState } from 'react'
import { Button } from '@/atoms/Button'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { HeadingXS } from '@/atoms/Text'
import { Maybe, Quiz, QuizQuestion, QuizResult } from '@/types/codegen-contentful'
import { buildCloudinaryImageUrlFromContentfulObject } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'
import { QuizQuestionDisplay } from './QuizQuestionDisplay'
import { QuizResultDisplay } from './QuizResultDisplay'
import { calculateResult } from './QuizUtils'

export const QuizDisplay: React.FC<Quiz> = ({ banner, questionsCollection, resultsCollection, sys, title }) => {
  const { t } = useTranslate('common')
  const [result, setResult] = useState<Maybe<QuizResult> | undefined>()
  const [answers, setAnswers] = useState<Record<string, string | undefined>>(
    questionsCollection?.items.reduce((acc, obj) => {
      if (obj?.sys.id) {
        acc[obj.sys.id] = undefined
      }
      return acc
    }, {} as Record<string, undefined>) || {},
  )
  const [showResult, setShowResult] = useState(false)

  const handleChange = (event: FormEvent<HTMLFormElement>) => {
    const { target } = event

    if (target instanceof HTMLInputElement && target.nodeName === 'INPUT' && target.parentElement?.id) {
      setAnswers({ ...answers, [target.parentElement.id]: target.value })
    }
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setResult(calculateResult(answers, resultsCollection))
    setShowResult(true)
  }

  return (
    <form id={sys.id} className="quiz-container" onChange={handleChange} onSubmit={handleSubmit}>
      {(banner?.length || 0) > 0 && (
        <div className="relative my-4 h-60 w-full md:h-80 lg:h-[420px]">
          <Image
            alt="Quiz Banner"
            layout="fill"
            loading="lazy"
            objectFit="cover"
            src={buildCloudinaryImageUrlFromContentfulObject(banner?.[0], 'c_fill,w_672')}
          />
        </div>
      )}
      <HeadingXS className="mb-4">{title}</HeadingXS>
      {!showResult && (
        <>
          <fieldset>
            {questionsCollection?.items?.map((question) => {
              return <QuizQuestionDisplay key={question?.sys.id} {...(question as QuizQuestion)} />
            })}
          </fieldset>
          <Button disabled={false} type="submit" variant="black">
            {t('submit', 'Submit')}
          </Button>
        </>
      )}
      {showResult && result && <QuizResultDisplay {...result} />}
    </form>
  )
}
