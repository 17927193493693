import Bugsnag from '@bugsnag/js'
import { ParsedUrlQuery } from 'querystring'

/**
 * @param url the fully formed URL, cannot be relative
 * @param extraQueryParams
 */
export const mergeQueryParameters = (
  url: string,
  extraQueryParams: ParsedUrlQuery | Record<string, string>,
): string => {
  try {
    const urlObject = new URL(url)
    Object.entries(extraQueryParams).forEach(([key, value]) => {
      if (!urlObject.searchParams?.get(key)) {
        urlObject.searchParams.append(key, value as string)
      }
    })

    return urlObject.toString()
  } catch (e) {
    Bugsnag.notify(`Error merging query params error=${e}`)
    return url
  }
}

export const mergeUrlParams = (url: string, additionalParams: Record<string, string> = {}): string => {
  try {
    if (typeof window === 'undefined') return url

    const currentParams = new URLSearchParams(window.location.search)
    const newUrl = new URL(url, window.location.origin)

    // Merge current URL parameters into the new URL
    currentParams.forEach((value, key) => {
      newUrl.searchParams.set(key, value)
    })

    // Add additional parameters to the new URL
    Object.entries(additionalParams).forEach(([key, value]) => {
      newUrl.searchParams.set(key, value)
    })

    return newUrl.toString()
  } catch (e) {
    Bugsnag.notify(`Error merging query params error=${e}`)
    return url
  }
}
