import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { ParagraphMD, ParagraphXL } from '@/atoms/Text'
import { QuizResult } from '@/types/codegen-contentful'
import { buildCloudinaryImageUrlFromContentfulObject } from '@/utils/Cloudinary'

export const QuizResultDisplay: React.FC<QuizResult> = ({ image, name, text }) => {
  return (
    <div className="py-2">
      {image.length > 0 && (
        <div className="relative my-4 h-60 w-full md:h-80 lg:h-[420px]">
          <Image
            alt={name as string}
            layout="fill"
            loading="lazy"
            objectFit="cover"
            src={buildCloudinaryImageUrlFromContentfulObject(image?.[0], 'c_fill,w_672')}
          />
        </div>
      )}
      <ParagraphXL>{name}</ParagraphXL>
      <ParagraphMD>{text}</ParagraphMD>
    </div>
  )
}
