import { Maybe, QuizResultsCollection } from '@/types/codegen-contentful'

export function calculateResult(answers: Record<string, string | undefined>, results?: Maybe<QuizResultsCollection>) {
  const tally = initializeTally(results)
  tallyResults(answers, tally)
  return results?.items.find((result) => result?.sys.id === findResult(tally))
}

export function initializeTally(results?: Maybe<QuizResultsCollection>) {
  return (
    results?.items.reduce((acc, obj) => {
      if (obj?.sys.id) {
        acc[obj.sys.id] = 0
      }
      return acc
    }, {} as Record<string, number>) || {}
  )
}

export function tallyResults(answers: Record<string, string | undefined>, tally: Record<string, number>) {
  Object.values(answers).forEach((values) => {
    values?.split(',').forEach((value) => {
      tally[value]++
    })
  })
}

export function findResult(tally: Record<string, number>) {
  let highestScore = 0
  let highestResult = ''

  Object.entries(tally).forEach(([key, value]) => {
    if (value > highestScore) {
      highestResult = key
      highestScore = value
    }
  })

  return highestResult
}
