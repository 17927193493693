import { LabelSM } from '@/atoms/Text'
import { QuizAnswer } from '@/types/codegen-contentful'

export const QuizAnswerDisplay: React.FC<QuizAnswer & { id: string; name: string }> = ({
  id,
  name,
  text,
  results,
  sys,
}) => {
  return (
    <div className="my-4 flex gap-2" id={id}>
      <input id={sys.id} name={name} required type="radio" value={results as string[]} />
      <LabelSM as={(props) => <label htmlFor={sys.id} {...props} />}>{text}</LabelSM>
    </div>
  )
}
